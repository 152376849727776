@keyframes member-preview-slide-up {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
}
.member-container .member {
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  align-items: center;
  cursor: pointer;
}
.member-container .profile-picture {
  display: grid;
  align-items: center;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 1.5rem;
}
.member-container .member > img {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.25rem;
}
.member-container .profile-picture img {
  object-fit: cover;
}
.member-container .member-preview {
  animation: member-preview-slide-up 0.1s;
}
@media (hover: hover) {
  .member-container .member {
    transition: 0.2s;
  }
  .member-container .member:hover {
    background-color: var(--light-brown-translucent);
  }
}
/* single member page */
.single-member .cover-photo {
  height: 12rem;
}
.single-member .profile-picture {
  position: relative;
  left: calc(4rem + -1 * min(100%, 55ch) / 2);
  width: 7rem;
  height: 7rem;
  margin: -5rem auto 0 auto;
  padding: 0;
  border: 0.25rem solid var(--glass);
  border-radius: 3.5rem;
  overflow: hidden;
  text-align: left;
}
.single-member img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single-member .profile-picture + .overview {
  text-align: right;
  position: relative;
  top: -4.25rem;
}
.single-member .profile-picture + .overview h1 {
  width: 63%;
  margin-left: 37%;
}
.single-member .bio:nth-child(2n), .single-member .accomplishment:nth-child(2n + 1) {
  text-align: right;
  padding: 1rem;
  border-right: 0.5rem solid var(--light-brown);
}
.single-member .bio:nth-child(2n + 1), .single-member .accomplishment:nth-child(2n) {
  text-align: left;
  padding: 1rem;
  border-left: 0.5rem solid var(--light-brown);
}