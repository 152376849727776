.dashboard svg {
  max-width: 100px;
  margin: 1rem auto;
}
.dashboard-quick-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
}
@media (max-width: 1100px) {
  .dashboard-quick-links {
    grid-template-columns: 1fr 1fr;
  } 
}
.logout-button >img {
  transform: scale(1.2);
}