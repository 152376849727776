@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
html, body {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  min-height: 100vh;
}
* {
  color: var(--dark-brown);
  font-family: var(--font-family);
  letter-spacing: var(--letter-spacing);
  font-weight: 400;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input, [contenteditable=true] {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
img {
  width: 100%;
}
:root {
  /* colors */
  --dark-brown: hsla(3, 30%, 10%, 1);
  --brown: hsla(3, 30%, 40%, 1);
  --light-brown: hsla(3, 30%, 80%, 1);
  --light-brown-translucent: hsla(3, 30%, 80%, 0.3);
  --glass: hsla(0, 0%, 100%, 0.9);
  --glass-dark: hsla(0, 0%, 50%, 0.7);
  --glass-light: hsla(0, 0%, 100%, 0.6);
  --clr-error: hsla(0, 70%, 70%, 1);
  --clr-warning: hsla(45, 70%, 70%, 1);
  --clr-info: hsl(90, 50%, 80%, 1);
  --white: hsla(0, 100%, 100%, 1);
  --black: hsla(0, 100%, 0%, 1);
  --gray: hsla(0, 10%, 50%, 0.8);
  /* text */
  --font-family: 'Raleway', sans-serif;
  --letter-spacing: 0.125rem;
  /* z index */
  --hide-z-index: -5;
  --background-z-index: -1;
  --nav-z-index: 5;
  --freeze-z-index: 10;
  /* sizes */
  --profile-pic-size: 3rem;
}
.background-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  z-index: var(--background-z-index);
}
.background-container .background-image {
  height: 100vmax;
  width: 150vmax;
  margin-top: calc((-100vmax + 100vh) / 2);
  margin-left: calc((-150vmax + 100vw) / 2);
}
.grid {
  display: grid;
}
.grid.align-center {
  align-items: center;
}
.grid.justify-center {
  justify-items: center;
}
.grid.text-center {
  text-align: center;
}
.grid.cols-5 {
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 600px) {
  .grid.cols-5 {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid.cols-5 :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .grid.cols-5 > :nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .grid.cols-5 :nth-child(3) {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  .grid.cols-5 :nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .grid.cols-5 :nth-child(5) {
    grid-column-start: 4;
    grid-column-end: 7;
  }
}
.glass-panel {
  margin: 1.5rem auto 1rem auto;
  width: max(80vw, 300px);
  background-color: var(--glass);
  box-shadow: 1px 2px 5px var(--glass);
  border-radius: 1rem;
  padding: 0.75rem;
}
@media (hover: none) {
  .glass-panel {
    width: 94vw;
  }
}
.freeze {
  transition: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--glass-light);
  z-index: var(--hide-z-index);
}
.freeze.frozen {
  z-index: var(--freeze-z-index);
  background-color: var(--glass-dark);
}
.alert {
  transition: 0.5s;
  display: grid;
  position: fixed;
  top: 1rem;
  left: 0;
  right: 0;
  z-index: var(--freeze-z-index);
  opacity: 0;
}
.alert p {
  margin: auto;
  width: min(74vw, 50ch);
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
}
.alert p .header {
  font-weight: 600;
}
.alert.alert-closed {
  -webkit-transform: translateY(-5rem);
          transform: translateY(-5rem);
}
.alert.alert-open {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.alert.info p {
  background-color: var(--clr-info);
  color: var(--black);
  box-shadow: 1px 2px 5px var(--gray);
}
.alert.warning p {
  background-color: var(--clr-warning);
  color: var(--black);
  box-shadow: 1px 2px 5px var(--gray);
}
.alert.error p {
  background-color: var(--clr-error);
  color: var(--black);
  box-shadow: 1px 2px 5px var(--gray);
}
.alert-action {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .alert {
    top: unset;
    bottom: 1rem;
  }
  .alert.alert-closed {
    -webkit-transform: translateY(5rem);
            transform: translateY(5rem);
  }
}
/* forms */
section.form-group label {
  padding: 0.125rem;
  display: block;
}
section.form-group input {
  padding: 0.125rem;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed transparent;
  outline: none;
  width: 100%;
}
section.form-group input:focus {
  border-bottom: 1px dashed var(--dark-brown);
}
section.form-group .radio-button {
  display: grid;
  grid-template-columns: 2rem 1fr;
  margin: 0.75rem 0.25rem;
  align-items: center;
}
section.form-group input[type="file"] {
  display: none;
}
section.form-group a, .clickable-action {
  color: var(--brown);
  cursor: pointer;
}
section.form-group label.search {
  display: grid;
  grid-template-columns: 1fr 1rem;
  align-self: center;
  padding: 0.5rem 1rem;
  border: 1px dashed var(--dark-brown);
}
section.form-group label.search input {
  border: none;
}
section.form-group label.search img {
  -webkit-transform: translateY(0.125rem);
          transform: translateY(0.125rem);
}
/* main */
main > header:first-child {
  margin: 0.5rem 1rem 1.5rem 1rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1rem 1fr 1rem;
  align-items: center;
  text-align: center;
}
main > header:first-child > h1 {
  text-decoration: underline;
  grid-column: 2;
}
main :is(p, section, div, ul, hr) {
  width: 100%;
  max-width: 55ch;
  margin: 1.5rem auto;
}
main .breakout:is(p, section, div, ul, hr) {
  max-width: 87ch;
}
main header span {
  cursor: pointer;
}
@media (min-width: 1400px) {
  main:not(.single-member) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  main > header, main > .breakout:is(p, section, div, ul, hr) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  main > hr {
    display: none;
  }
}
.underline {
  text-decoration: underline;
}
/* buttons */
button {
  transition: 0.3s;
  margin: 0.75rem;
  border: 1px solid var(--brown);
  color: var(--brown);
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  cursor: pointer;
}
button:not(:disabled):hover {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: var(--brown);
  color: var(--glass);
}
button:disabled {
  color: var(--light-brown);
  border: 1px solid var(--light-brown);
}
button img {
  transition: 0.3s;
  display: inline;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}
button:first-of-type:last-of-type {
  margin: 0.75rem 0;
}
a {
  color: var(--brown);
  cursor: pointer;
}
@media (hover: hover) {
  button:not(:disabled):hover {
    border: 1px solid transparent;
    border-radius: 0.5rem;
    background-color: var(--brown);
    color: var(--glass);
  }
  button:hover img {
    -webkit-filter: invert(1);
            filter: invert(1);
  }
}
/* figures */
div.gallery {
  display: grid;
  margin: auto;
  grid-gap: 1ch;
  gap: 1ch;
  grid-template-columns: repeat(3, calc(min(53ch, 80vw) / 3));
  grid-template-areas:"a b b"
                      "c b b"
                      "d e f"
                      "g g h"
                      "g g i"
                      "l k j";
}
figure.square {
  overflow: hidden;
  margin: 0;
  height: calc(min(53ch, 80vw) / 3);
  width: calc(min(53ch, 80vw) / 3);
}
figure.square img, figure.square video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
figure.square:nth-child(1) {
  grid-area: a;
}
figure.square:nth-child(2) {
  grid-area: b;
  height: calc(1ch + 2 * min(53ch, 80vw) / 3);
  width: calc(1ch + 2 * min(53ch, 80vw) / 3);
}
figure.square:nth-child(3) {
  grid-area: c;
}
figure.square:nth-child(4) {
  grid-area: d;
}
figure.square:nth-child(5) {
  grid-area: e;
}
figure.square:nth-child(6) {
  grid-area: f;
}
figure.square:nth-child(7) {
  grid-area: g;
}
figure.square:nth-child(8) {
  grid-area: h;
}
figure.square:nth-child(9) {
  grid-area: i;
}
figure.square:nth-child(10) {
  grid-area: j;
}
figure.square:nth-child(11) {
  grid-area: k;
}
figure.square:nth-child(12) {
  grid-area: l;
}
/* list controls */
.controls {
  padding: 1rem;
  text-align: center;
}
.controls span.clickable-action {
  font-weight: 600;
  padding: 0.75rem;
  letter-spacing: 0.2rem;
  cursor: pointer;
}
.controls span.prev {
  color: #665;
}
.controls .form-block-container {
  text-align: center;
}
.controls .form-block {
  cursor: pointer;
  display: inline-block;
  margin: 0.875rem;
  height: 0.5rem;
  width: 0.5rem;
  background-color: var(--light-brown);
}
.controls .form-block.active {
  cursor: unset;
  background-color: var(--brown);
  outline-color: var(--brown);
  margin: 0.825rem;
  height: 0.675rem;
  width: 0.675rem;
}
/* content-editable */
[contenteditable=true] {
  outline: none;
  padding: 0.25rem 0;
  border-bottom: 1px dashed var(--brown);
}
/* array editor */
section.array-editor-group {
  margin: 2rem auto;
}
section.array-editor-group div {
  margin: 0;
}
/* size/hover media queries */
@media (max-width: 1399px) {
  .big-boi {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .pint-size {
    display: none !important;
  }
}
@media (hover: hover) {
  .touch-only {
    display: none !important;
  }
}
/* images */
.profile-picture {
  display: grid;
  margin: 0;
  align-items: center;
  width: var(--profile-pic-size);
  height: var(--profile-pic-size);
  overflow: hidden;
  border-radius: calc(var(--profile-pic-size) / 2);
}
.profile-picture img {
  width: var(--profile-pic-size);
  height: var(--profile-pic-size);
  object-fit: cover;
}

.nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--nav-z-index);
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: var(--glass-light);
}
.nav.closed {
  display: none;
}
.nav ul {
  display: grid;
  text-align: center;
  list-style-type: none;
  width: min(45ch, 84vw);
  padding: 2rem 1rem;
  margin: 2rem;
  background-color: var(--dark-brown);
  box-shadow: 1px 2px 5px var(--dark-brown);
}
.nav li {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 1.5rem 0.25rem;
  color: var(--glass-light);
  cursor: pointer;
}
.nav li:hover {
  color: var(--glass)
}
.nav-button {
  transition: 0.3s;
  position: fixed;
  left: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 2rem;
  background-color: var(--glass-light);
  box-shadow: 1px 2px 5px var(--glass-light);
}
.nav-button:hover {
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
}
.nav-button.open {
  background-color: transparent;
  box-shadow: none;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
@media (hover: none) {
  .nav-button {
    display: none;
  }
}
.auth-container > header {
  display: grid;
  justify-content: center;
  align-items: center;
}
.auth-container h1 {
  margin: 0.5rem 0 1.5rem 0;
  text-decoration: underline;
}
.auth-container label {
  padding: 0.125rem;
  display: block;
}
.auth-container input {
  padding: 0.125rem;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed transparent;
  outline: none;
  width: 100%;
}
.auth-container input:focus {
  border-bottom: 1px dashed var(--dark-brown);
}
.auth-container button {
  transition: 0.3s;
  margin: 1rem 0;
  border: 1px solid var(--brown);
  color: var(--brown);
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
}
.auth-container button:hover {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: var(--brown);
  color: var(--glass);
}
.create-invite-container header {
  text-align: center;
}
.create-invite-container h1 {
  margin: 0.5rem 0 1.5rem 0;
  text-decoration: underline;
}
.create-invite-container .controls {
  padding: 1rem;
  text-align: center;
}
@-webkit-keyframes location-preview-slide-up {
  0% {
    -webkit-transform: translateY(2rem);
            transform: translateY(2rem);
    opacity: 0;
  }
}
@keyframes location-preview-slide-up {
  0% {
    -webkit-transform: translateY(2rem);
            transform: translateY(2rem);
    opacity: 0;
  }
}
.locations-container .location {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
}
.locations-container .location :first-child {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
}
.locations-container .location:hover {
  background-color: var(--light-brown-translucent);
}
.single-location-preview {
  display: none;
}
@media (min-width: 1400px) {
  .single-location-preview {
    display: block;
    -webkit-animation: location-preview-slide-up 0.2s;
            animation: location-preview-slide-up 0.2s;
  }
}
@-webkit-keyframes member-preview-slide-up {
  0% {
    -webkit-transform: translateY(2rem);
            transform: translateY(2rem);
    opacity: 0;
  }
}
@keyframes member-preview-slide-up {
  0% {
    -webkit-transform: translateY(2rem);
            transform: translateY(2rem);
    opacity: 0;
  }
}
.member-container .member {
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  align-items: center;
  cursor: pointer;
}
.member-container .profile-picture {
  display: grid;
  align-items: center;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 1.5rem;
}
.member-container .member > img {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.25rem;
}
.member-container .profile-picture img {
  object-fit: cover;
}
.member-container .member-preview {
  -webkit-animation: member-preview-slide-up 0.1s;
          animation: member-preview-slide-up 0.1s;
}
@media (hover: hover) {
  .member-container .member {
    transition: 0.2s;
  }
  .member-container .member:hover {
    background-color: var(--light-brown-translucent);
  }
}
/* single member page */
.single-member .cover-photo {
  height: 12rem;
}
.single-member .profile-picture {
  position: relative;
  left: calc(4rem + -1 * min(100%, 55ch) / 2);
  width: 7rem;
  height: 7rem;
  margin: -5rem auto 0 auto;
  padding: 0;
  border: 0.25rem solid var(--glass);
  border-radius: 3.5rem;
  overflow: hidden;
  text-align: left;
}
.single-member img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single-member .profile-picture + .overview {
  text-align: right;
  position: relative;
  top: -4.25rem;
}
.single-member .profile-picture + .overview h1 {
  width: 63%;
  margin-left: 37%;
}
.single-member .bio:nth-child(2n), .single-member .accomplishment:nth-child(2n + 1) {
  text-align: right;
  padding: 1rem;
  border-right: 0.5rem solid var(--light-brown);
}
.single-member .bio:nth-child(2n + 1), .single-member .accomplishment:nth-child(2n) {
  text-align: left;
  padding: 1rem;
  border-left: 0.5rem solid var(--light-brown);
}
.chat-preview {
  display: grid;
  align-items: center;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 2rem 2rem;
  grid-template-areas: "image name"
                       "image message";
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.chat-preview .profile-picture {
  display: grid;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  overflow: hidden;
}
.chat-preview > img, .chat-preview .profile-picture {
  grid-area: image;
}
.chat-preview .chat-member-name {
  grid-area: name;
  font-weight: 600;
}
.chat-preview .chat-member-message {
  grid-area: message;
}
/* single chat page */
.single-chat .profile-picture {
  margin: 0 auto;
  width: 12rem;
  height: 12rem;
  border-radius: 6rem;
  border: 0.1rem solid var(--dark-brown);
  overflow: hidden;
}
.single-chat .profile-picture img {
  width: 12rem;
  height: 12rem;
  object-fit: cover;
}
.single-chat .message-container {
  max-height: 60vh;
  overflow-y: scroll;
  padding: 0.25rem;
}
.single-chat .message-container.bottom {
  background: linear-gradient(var(--light-brown-translucent) 0%, transparent 3%);
}
.single-chat .message-container.top {
  background: linear-gradient(transparent 0%, transparent 97%, var(--light-brown-translucent) 100%);
}
.single-chat .message-container.middle {
  background: linear-gradient(var(--light-brown-translucent) 0%, transparent 3%, transparent 97%, var(--light-brown-translucent) 100%);
}
.single-chat .message {
  display: grid;
  padding: 0;
  margin: 1rem 0 0 0;
  width: 80%;
}
.single-chat .message span {
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
.single-chat .message.from {
  justify-items: right;
  margin-left: 20%;
}
.single-chat .message.from span {
  background-color: var(--light-brown-translucent);
}
.single-chat .message.to {
  justify-items: left;
}
.single-chat .message.to span {
  color: var(--white);
  background-color: var(--brown);
}
.single-chat .chat-send-message {
  margin-top: 2rem;
}
.single-chat .chat-send-message {
  outline: none;
  border: 0;
  border-bottom: 1px dashed var(--brown);
  background-color: transparent;
  width: 100%;
}
@media (hover: hover) {
  .chat-preview {
    transition: 0.2s;
  }
  .chat-preview:hover {
    background-color: var(--light-brown-translucent);
  }
  .single-chat .chat-send-message-button {
    display: none;
  }
}
.dashboard svg {
  max-width: 100px;
  margin: 1rem auto;
}
.dashboard-quick-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
}
@media (max-width: 1100px) {
  .dashboard-quick-links {
    grid-template-columns: 1fr 1fr;
  } 
}
.logout-button >img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
@-webkit-keyframes swipe-suggestion-fade-in {
  0% {
    opacity: 0;
  }
}
@keyframes swipe-suggestion-fade-in {
  0% {
    opacity: 0;
  }
}
@-webkit-keyframes swipe-suggestion-animation {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  30% {
    -webkit-transform: translateX(20vmin);
            transform: translateX(20vmin);
  }
  90% {
    -webkit-transform: translateX(20vmin);
            transform: translateX(20vmin);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes swipe-suggestion-animation {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  30% {
    -webkit-transform: translateX(20vmin);
            transform: translateX(20vmin);
  }
  90% {
    -webkit-transform: translateX(20vmin);
            transform: translateX(20vmin);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.swipe-suggestion-container {
  display: none;
  position: fixed;
  align-items: center;
  justify-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--freeze-z-index);
  background-color: var(--light-brown-translucent);
}
.swipe-suggestion-container div {
  width: 50vmin;
  height: 50vmin;
  background-color: var(--dark-brown);
  border-radius: 5vmin;
}
.swipe-suggestion-container img {
  position: relative;
  -webkit-filter: invert(1);
          filter: invert(1);
  left: 5vmin;
  top: 10vmin;
  width: 20vmin;
  height: 20vmin;
  -webkit-animation: swipe-suggestion-animation 3s infinite;
          animation: swipe-suggestion-animation 3s infinite;
}
.swipe-suggestion-container p {
  color: white;
  position: relative;
  left: 5vmin;
  top: 10vmin;
  width: 40vmin;
  text-align: center;
}
@media (hover: none) {
  .swipe-suggestion-container.show {
    display: grid;
    -webkit-animation: swipe-suggestion-fade-in 1s;
            animation: swipe-suggestion-fade-in 1s;
  }
}
.forum-thread {
  display: grid;
  align-items: center;
  grid-template-columns: 3rem 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1.5rem 0.5rem;
}
.forum-thread header {
  display: grid;
  grid-template-columns: 1fr 3ch;
  grid-template-rows: 2rem 1rem;
  grid-template-areas: "member-name comment-count"
                       "date-posted comment-count";
}
.forum-thread header h3 {
  grid-area: member-name;
  margin: 0;
}
.forum-thread header p {
  grid-area: date-posted;
  margin: 0;
  font-size: 0.75rem;
}
.forum-thread header h4 {
  margin: 0;
  grid-area: comment-count;
}
.forum-thread > p {
  margin: 1rem 0;
  max-width: 100%;
}
.forum-thread div {
  height: calc(100% - 2rem);
  width: 0px;
  border-right: 2px dashed var(--brown);
}
@media (hover: hover) {
  .forum-thread.clickable {
    cursor: pointer;
  }
  .forum-thread.clickable:hover {
    background-color: var(--light-brown-translucent)
  }
}

