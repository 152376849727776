html, body {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  min-height: 100vh;
}
* {
  color: var(--dark-brown);
  font-family: var(--font-family);
  letter-spacing: var(--letter-spacing);
  font-weight: 400;
  box-sizing: border-box;
  user-select: none;
}
input, [contenteditable=true] {
  user-select: text;
}
img {
  width: 100%;
}