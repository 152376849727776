.grid {
  display: grid;
}
.grid.align-center {
  align-items: center;
}
.grid.justify-center {
  justify-items: center;
}
.grid.text-center {
  text-align: center;
}
.grid.cols-5 {
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 600px) {
  .grid.cols-5 {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid.cols-5 :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .grid.cols-5 > :nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .grid.cols-5 :nth-child(3) {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  .grid.cols-5 :nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .grid.cols-5 :nth-child(5) {
    grid-column-start: 4;
    grid-column-end: 7;
  }
}