.chat-preview {
  display: grid;
  align-items: center;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 2rem 2rem;
  grid-template-areas: "image name"
                       "image message";
  column-gap: 1rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.chat-preview .profile-picture {
  display: grid;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  overflow: hidden;
}
.chat-preview > img, .chat-preview .profile-picture {
  grid-area: image;
}
.chat-preview .chat-member-name {
  grid-area: name;
  font-weight: 600;
}
.chat-preview .chat-member-message {
  grid-area: message;
}
/* single chat page */
.single-chat .profile-picture {
  margin: 0 auto;
  width: 12rem;
  height: 12rem;
  border-radius: 6rem;
  border: 0.1rem solid var(--dark-brown);
  overflow: hidden;
}
.single-chat .profile-picture img {
  width: 12rem;
  height: 12rem;
  object-fit: cover;
}
.single-chat .message-container {
  max-height: 60vh;
  overflow-y: scroll;
  padding: 0.25rem;
}
.single-chat .message-container.bottom {
  background: linear-gradient(var(--light-brown-translucent) 0%, transparent 3%);
}
.single-chat .message-container.top {
  background: linear-gradient(transparent 0%, transparent 97%, var(--light-brown-translucent) 100%);
}
.single-chat .message-container.middle {
  background: linear-gradient(var(--light-brown-translucent) 0%, transparent 3%, transparent 97%, var(--light-brown-translucent) 100%);
}
.single-chat .message {
  display: grid;
  padding: 0;
  margin: 1rem 0 0 0;
  width: 80%;
}
.single-chat .message span {
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
.single-chat .message.from {
  justify-items: right;
  margin-left: 20%;
}
.single-chat .message.from span {
  background-color: var(--light-brown-translucent);
}
.single-chat .message.to {
  justify-items: left;
}
.single-chat .message.to span {
  color: var(--white);
  background-color: var(--brown);
}
.single-chat .chat-send-message {
  margin-top: 2rem;
}
.single-chat .chat-send-message {
  outline: none;
  border: 0;
  border-bottom: 1px dashed var(--brown);
  background-color: transparent;
  width: 100%;
}
@media (hover: hover) {
  .chat-preview {
    transition: 0.2s;
  }
  .chat-preview:hover {
    background-color: var(--light-brown-translucent);
  }
  .single-chat .chat-send-message-button {
    display: none;
  }
}