.auth-container > header {
  display: grid;
  justify-content: center;
  align-items: center;
}
.auth-container h1 {
  margin: 0.5rem 0 1.5rem 0;
  text-decoration: underline;
}
.auth-container label {
  padding: 0.125rem;
  display: block;
}
.auth-container input {
  padding: 0.125rem;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed transparent;
  outline: none;
  width: 100%;
}
.auth-container input:focus {
  border-bottom: 1px dashed var(--dark-brown);
}
.auth-container button {
  transition: 0.3s;
  margin: 1rem 0;
  border: 1px solid var(--brown);
  color: var(--brown);
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
}
.auth-container button:hover {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: var(--brown);
  color: var(--glass);
}